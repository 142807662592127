import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { s3baseUrl } from "src/config/config";

const AudioGraphRepresentation = ({ data }) => {
  const [barChart, setBarChart] = useState(null);

  useEffect(() => {
    if (data) {
      const count_Array = data.map((item) => item.listen_count);
      const colors = data.map(() => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
      });
      const categories = data.map((item, index) => "Audio " + (index + 1));
      const totalCounts = data.reduce((a, b) => a + b.listen_count, 0);
      let DataObject = {
        series: [
          {
            data: count_Array,
          },
        ],
        options: {
          tooltip: {
            shared: true,
            intersect: false,
            marker: {
              fillColors: colors,
            },
            y: {
              title: {
                formatter: function (seriesName, opts) {
                  return opts.w.config.xaxis.categories[opts.dataPointIndex];
                },
              },
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const color = w.config.colors[dataPointIndex];
              const category = w.config.xaxis.categories[dataPointIndex];
              const value = series[seriesIndex][dataPointIndex];
              const url = data[dataPointIndex].url;
              return (
                '<div style="display: flex; align-items: center; padding: 10px;">' +
                '<div style="width: 12px; height: 12px; border-radius: 50%; background-color: ' +
                color +
                '; margin-right: 10px;"></div>' +
                "<div>" +
                "<strong>" +
                category +
                "</strong>" +
                "   :  " +
                value +
                " Times " +
                "<br/>" +
                "<p>" +
                url +
                "</p>" +
                "</div>" +
                "</div>"
              );
            },
          },
          chart: {
            height: 350,
            type: "bar",
            events: {
              click: function (chart, w, e) {
                // console.log(chart, w, e)
              },
            },
          },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: "20%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: categories,
            labels: {
              style: {
                colors: "#fff",
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#c6c2c2",
              },
            },
            axisBorder: {
              show: true,
              color: "gray",
              dashArray: 4,
            },
            max: totalCounts + 9,
            min: 0,
          },
        },
      };
      setBarChart(DataObject);
    }
  }, [data]);

  if (!barChart) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card" style={{ color: "#fff" }}>
      <div className="card-header ">
        <h4 className="ps-4  pt-3 mb-0">Listen Audios</h4>
        <div className="">
          <ReactApexChart
            options={barChart.options}
            series={barChart.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioGraphRepresentation;
